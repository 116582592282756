/*
 * @Author: your name
 * @Date: 2019-11-11 16:10:25
 * @LastEditTime: 2019-11-16 16:53:51
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \caredily66\src\router\index.js
 */
import Vue from 'vue';
import Router from 'vue-router';
import index from '@/views/index';
import des from "@/cmmon/js/des.js";
let Base64 = require("js-base64").Base64;
Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: '/',
      redirect: '/index'
    },
    // {
    //   name: 'index',
    //   path: '/index',
    //   component: index
    // },
    {
      // name: 'homeIndex',
      // path: '/homeIndex',
      name: 'index',
      path: '/index',
      component: (resolve) => {
        require(['../views/homeIndex/index.vue'], resolve);
      }
    },
    {
      name: 'Messagepush',
      path: '/Messagepush',
      component: (resolve) => {
        require(['../views/index/Messagepush.vue'], resolve);
      }
    },
    // wcmaterial
    {
      name: 'wcmaterial',
      path: '/wcmaterial',
      component: (resolve) => {
        require(['../views/index/wcmaterial.vue'], resolve);
      }
    },
    // login
    {
      path: '/login',
      name: 'login',
      component: (resolve) => {
        require(['../views/login/login.vue'], resolve);
      }
    },
    {
      path: '/forgotpwd',
      name: 'forgotpwd',
      component: (resolve) => {
        require(['../views/login/forgotpwd.vue'], resolve);
      }
    },
    {
      path: '/wxlogin',
      name: 'wxlogin',
      component: (resolve) => {
        require(['../views/login/wxlogin.vue'], resolve);
      }
    },
    {
      path: '/error',
      name: 'error',
      component: (resolve) => {
        require(['../views/error/error.vue'], resolve);
      }
    },
    {
      path: '/errorpage',
      name: 'errorpage',
      component: (resolve) => {
        require(['../views/error/errorPage.vue'], resolve);
      }
    },
    {
      path: '/system',
      name: 'system',
      component: (resolve) => {
        require(['../views/error/system.vue'], resolve);
      }
    },
    {
      path: '/registered',
      name: 'registered',
      component: (resolve) => {
        require(['../views/me/registered/registered.vue'], resolve);
      }
    },
    {
      path: '/registered2',
      name: 'registered2',
      component: (resolve) => {
        require(['../views/me/registered/registered2.vue'], resolve);
      }
    },
    {
      path: '/successful',
      name: 'successful',
      component: (resolve) => {
        require(['../views/me/registered/successful.vue'], resolve);
      }
    },
    {
      path: '/newcontract',
      name: 'newcontract',
      component: (resolve) => {
        require(['../views/me/registered/newcontract.vue'], resolve);
      }
    },
    {
      path: '/promotionList',
      name: 'promotionList',
      component: (resolve) => {
        require(['../views/promotion/promotionList.vue'], resolve);
      }
    },
    {
      path: '/ykypromotionList',
      name: 'ykypromotionList',
      component: (resolve) => {
        require(['../views/promotion/ykypromotionList.vue'], resolve);
      }
    },
    {
      path: '/promotionActive',
      name: 'promotionActive',
      component: (resolve) => {
        require(['../views/newproduct/promotionActive.vue'], resolve);
      }
    },
    {
      path: '/goodsgroup',
      name: 'goodsgroup',
      component: (resolve) => {
        require(['../views/newproduct/goodsgroup.vue'], resolve);
      }
    },
    {
      path: '/static',
      name: 'static',
      component: (resolve) => {
        require(['../views/promotion/static.vue'], resolve);
      }
    },
    {
      path: '/promotion/:id',
      name: 'promotion',
      component: (resolve) => {
        require(['../views/promotion/promotion.vue'], resolve);
      },
      meta: { noauth: true }
    },
    {
      path: '/ykypromotion/:id',
      name: 'ykypromotion',
      component: (resolve) => {
        require(['../views/promotion/ykypromotion.vue'], resolve);
      },
      meta: { noauth: true }
    },
    {
      path: '/activelist/:id',
      name: 'activelist',
      component: (resolve) => {
        require(['../views/promotion/newActivecenter/activelist.vue'], resolve);
      }
    },
    {
      path: '/newActiveShare',
      name: 'newActiveShare',
      component: (resolve) => {
        require(['../views/promotion/newActivecenter/newActiveShare.vue'], resolve);
      }
    },
    {
      path: '/productmsg',
      name: 'productmsg',
      component: (resolve) => {
        require(['../views/promotion/productmsg.vue'], resolve);
      }
    },
    {
      path: '/wxpay',
      name: 'wxpay',
      component: (resolve) => {
        require(['../views/promotion/wxpay.vue'], resolve);
      }
    },
    // message
    {
      path: '/messagedetails',
      name: 'messagedetails',
      component: (resolve) => {
        require(['../views/message/messagedetails.vue'], resolve);
      }
    },
    {
      path: '/message',
      name: 'message',
      component: (resolve) => {
        require(['../views/message/message.vue'], resolve);
      }
    },
    {
      path: '/allmessage',
      name: 'allmessage',
      component: (resolve) => {
        require(['../views/message/allmessage.vue'], resolve);
      }
    },
    {
      path: '/messagelist',
      name: 'messagelist',
      component: (resolve) => {
        require(['../views/message/messagelist.vue'], resolve);
      }
    },
    //通知组列
    {
      path: '/noticeList',
      name: 'noticeList',
      component: (resolve) => {
        require(['../views/message/noticeList.vue'], resolve);
      }
    },
     //通知组列
     {
      path: '/noticeclass',
      name: 'noticeclass',
      component: (resolve) => {
        require(['../views/message/noticeclass.vue'], resolve);
      }
    },
    // me
    {
      name: 'me',
      path: '/me',
      component: (resolve) => {
        require(['../views/me/me.vue'], resolve);
      }
    },
    {
      name: 'addPaymethod',
      path: '/addPaymethod',
      component: (resolve) => {
        require(['../views/me/addPaymethod.vue'], resolve);
      }
    }, {
      name: 'minemsg',
      path: '/minemsg',
      component: (resolve) => {
        require(['../views/me/minemsg.vue'], resolve);
      }
    },
    {
      path: 'electricity',
      name: 'electricity',
      component: (resolve) => {
        require(['../views/me/electricity.vue'], resolve);
      }
    },
    // 邀请页面
    {
      name: 'newAgent',
      path: '/newAgent',
      component: (resolve) => {
        require(['../views/me/newAgent.vue'], resolve);
      }
    },
    // 注册完成
    {
      name: 'recompleted',
      path: '/recompleted',
      component: (resolve) => {
        require(['../views/me/recompleted.vue'], resolve);
      }
    },
    // 我要授权
    {
      name: 'authorization',
      path: '/authorization',
      component: (resolve) => {
        require(['../views/me/authorization.vue'], resolve);
      }
    },
    // 我的业绩
    {
      name: 'myperformance',
      path: '/myperformance',
      component: (resolve) => {
        require(['../views/me/myperformance.vue'], resolve);
      }
    },
    // 我的发票
    {
      name: 'invoice',
      path: '/invoice',
      component: (resolve) => {
        require(['../views/me/invoice.vue'], resolve);
      }
    },
    // 申请发票
    {
      name: 'applyinvoice',
      path: '/applyinvoice',
      component: (resolve) => {
        require(['../views/me/applyinvoice.vue'], resolve);
      }
    },
    // 质量问题
    {
      name: 'qualityproblem',
      path: '/qualityproblem',
      meta: {
        keepAlive: true
      },
      component: (resolve) => {
        require(['../views/me/quality/qualityproblem.vue'], resolve);
      }
    },
    // 编辑
    {
      name: 'exitquality',
      path: '/exitquality',
      component: (resolve) => {
        require(['../views/me/quality/exitquality.vue'], resolve);
      }
    },
    // 售后详情
    {
      name: 'AftersaleDetails',
      path: '/AftersaleDetails',
      component: (resolve) => {
        require(['../views/me/quality/AftersaleDetails.vue'], resolve);
      }
    },
    // 物流
    {
      name: 'logistics',
      path: '/logistics',
      meta: {
        keepAlive: true
      },
      component: (resolve) => {
        require(['../views/me/logistics/logistics.vue'], resolve);
      }
    },
    // (新增、修改物流)
    {
      name: 'exitlogistics',
      path: '/exitlogistics',
      component: (resolve) => {
        require(['../views/me/logistics/exitlogistics.vue'], resolve);
      }
    },
    {
      name: 'selectQuerydelivery',
      path: '/selectQuerydelivery',
      component: (resolve) => {
        require(['../views/me/logistics/selectQuerydelivery.vue'], resolve);
      }
    },
    {
      name: 'complaintWo',
      path: '/complaintWo',
      component: (resolve) => {
        require(['../views/me/complaint/complaintWo.vue'], resolve);
      }
    },
    {
      name: 'exitcomplaintWo',
      path: '/exitcomplaintWo',
      component: (resolve) => {
        require(['../views/me/complaint/exitcomplaintWo.vue'], resolve);
      }
    },
    {
      name: 'cwDetails',
      path: '/cwDetails',
      component: (resolve) => {
        require(['../views/me/complaint/cwDetails.vue'], resolve);
      }
    },
    // 动态表单
    {
      name: 'dynamicList',
      path: '/dynamicList',
      component: (resolve) => {
        require(['../views/me/dynamicForm/dynamicList.vue'], resolve);
      }
    }, {
      name: 'formDetail',
      path: '/formDetail',
      component: (resolve) => {
        require(['../views/me/dynamicForm/formDetail.vue'], resolve);
      }
    },
    {
      name: 'participatoryDetail',
      path: '/participatoryDetail',
      component: (resolve) => {
        require(['../views/me/dynamicForm/participatoryDetail.vue'], resolve);
      }
    }, {
      name: 'audit',
      path: '/audit',
      component: (resolve) => {
        require(['../views/me/dynamicForm/audit.vue'], resolve);
      }
    },
    {
      name: 'evaluate',
      path: '/evaluate',
      component: (resolve) => {
        require(['../views/me/dynamicForm/evaluate.vue'], resolve);
      }
    },
    {
      name: 'auxiliary',
      path: '/auxiliary',
      component: (resolve) => {
        require(['../views/me/dynamicForm/auxiliary.vue'], resolve);
      }
    }, {
      name: 'dynamicDetails',
      path: '/dynamicDetails',
      component: (resolve) => {
        require(['../views/me/dynamicForm/dynamicDetails.vue'], resolve);
      }
    }, {
      name: 'addDynamic',
      path: '/addDynamic',
      component: (resolve) => {
        require(['../views/me/dynamicForm/addDynamic.vue'], resolve);
      }
    }, {
      name: 'submitFrom',
      path: '/submitFrom',
      component: (resolve) => {
        require(['../views/me/dynamicForm/submitFrom.vue'], resolve);
      }
    }, {
      name: 'rollList',
      path: '/rollList',
      component: (resolve) => {
        require(['../views/me/dynamicForm/rollList.vue'], resolve);
      }
    }, {
      name: 'wxpays',
      path: '/wxpays',
      component: (resolve) => {
        require(['../views/me/dynamicForm/wxpays.vue'], resolve);
      }
    }, {
      name: 'qrCode',
      path: '/qrCode',
      component: (resolve) => {
        require(['../views/me/dynamicForm/qrCode.vue'], resolve);
      }
    }, {
      name: 'audit',
      path: '/audit',
      component: (resolve) => {
        require(['../views/me/dynamicForm/audit.vue'], resolve);
      }
    }, {
      name: 'links',
      path: '/links',
      component: (resolve) => {
        require(['../views/me/dynamicForm/links.vue'], resolve);
      }
    }, {
      name: 'wxlinks',
      path: '/wxlinks',
      component: (resolve) => {
        require(['../views/me/dynamicForm/wxlinks.vue'], resolve);
      }
    }, {
      name: 'position',
      path: '/position',
      component: (resolve) => {
        require(['../views/me/dynamicForm/position.vue'], resolve);
      }
    }, {
      name: 'rollDetaily',
      path: '/rollDetaily',
      component: (resolve) => {
        require(['../views/me/dynamicForm/rollDetaily.vue'], resolve);
      }
    },
    // 售后说明
    {
      name: 'AftersaleExplain',
      path: '/AftersaleExplain',
      component: (resolve) => {
        require(['../views/me/AftersaleExplain.vue'], resolve);
      }
    },
    {
      name: 'reportcenter',
      path: '/reportcenter',
      component: (resolve) => {
        require(['../views/me/thereportcenter/reportcenter.vue'], resolve);
      }
    },
    {
      name: 'teamresults',
      path: '/teamresults',
      component: (resolve) => {
        require(['../views/me/thereportcenter/teamresults.vue'], resolve);
      }
    },
    {
      name: 'teamresultstop',
      path: '/teamresultstop',
      component: (resolve) => {
        require(['../views/me/thereportcenter/teamresultstop.vue'], resolve);
      }
    },
    {
      name: 'leveloutlist',
      path: '/leveloutlist',
      component: (resolve) => {
        require(['../views/me/thereportcenter/leveloutlist.vue'], resolve);
      }
    },
    {
      name: 'speciallist',
      path: '/speciallist',
      component: (resolve) => {
        require(['../views/me/thereportcenter/speciallist.vue'], resolve);
      }
    },
    {
      name: 'specialarealist',
      path: '/specialarealist',
      component: (resolve) => {
        require(['../views/me/thereportcenter/specialarealist.vue'], resolve);
      }
    },
    {
      name: 'unshipped',
      path: '/unshipped',
      component: (resolve) => {
        require(['../views/me/thereportcenter/unshipped.vue'], resolve);
      }
    },
    {
      name: 'togolist',
      path: '/togolist',
      component: (resolve) => {
        require(['../views/me/togolist.vue'], resolve);
      }
    },
    {
      name: 'pension',
      path: '/pension',
      component: (resolve) => {
        require(['../views/me/thereportcenter/pension.vue'], resolve);
      }
    },
    {
      name: 'hispension',
      path: '/hispension',
      component: (resolve) => {
        require(['../views/me/thereportcenter/hispension.vue'], resolve);
      }
    },
    {
      name: 'returnbond',
      path: '/returnbond',
      component: (resolve) => {
        require(['../views/me/thereportcenter/returnbond.vue'], resolve);
      }
    },
    {
      name: 'rebateall',
      path: '/rebateall',
      component: (resolve) => {
        require(['../views/me/thereportcenter/rebateall.vue'], resolve);
      }
    },
    {
      name: 'reward',
      path: '/reward',
      component: (resolve) => {
        require(['../views/me/thereportcenter/reward.vue'], resolve);
      }
    },
    {
      name: 'details',
      path: '/details',
      component: (resolve) => {
        require(['../views/me/thereportcenter/details.vue'], resolve);
      }
    },
    {
      name: 'offlinemoney',
      path: '/offlinemoney',
      component: (resolve) => {
        require(['../views/me/thereportcenter/offlinemoney.vue'], resolve);
      }
    },
    {
      name: 'returnbond',
      path: '/returnbond',
      component: (resolve) => {
        require(['../views/me/thereportcenter/returnbond.vue'], resolve);
      }
    },
    {
      name: 'levelManagement',
      path: '/levelManagement',
      component: (resolve) => {
        require(['../views/me/levelManagement.vue'], resolve);
      }
    },
    // 在途订单
    {
      name: 'Orderinransit',
      path: '/Orderintransit',
      component: (resolve) => {
        require(['../views/me/Orderintransit.vue'], resolve);
      }
    },
    // 在线支付订单
    {
      name: 'orderList',
      path: '/orderList',
      component: (resolve) => {
        require(['../views/me/orderList.vue'], resolve);
      }
    },
    // 待审核订单
    {
      name: 'reviewedList',
      path: '/reviewedList',
      component: (resolve) => {
        require(['../views/me/reviewedList.vue'], resolve);
      }
    },
    {
      name: 'reviewedDateil',
      path: '/reviewedDateil',
      component: (resolve) => {
        require(['../views/me/reviewedDateil.vue'], resolve);
      }
    },
    //促销资格
    {
      name: 'promotionQuality',
      path: '/promotionQuality',
      component: (resolve) => {
        require(['../views/me/promotion/list.vue'], resolve);
      }
    },
    // 专营店开票
    {
      name: 'StoreInvoice',
      path: '/StoreInvoice',
      component: (resolve) => {
        require(['../views/promotion/StoreInvoice.vue'], resolve);
      }
    },
    {
      path: '/shopCart2',
      name: 'shopCart2',
      component: (resolve) => {
        require(['../views/promotion/shopCart2.vue'], resolve);
      }
    },
    {
      path: '/ykyshopCart',
      name: 'ykyshopCart',
      component: (resolve) => {
        require(['../views/promotion/ykyshopCart.vue'], resolve);
      }
    },
    {
      name: 'expressage',
      path: '/expressage',
      component: (resolve) => {
        require(['../views/me/expressage.vue'], resolve);
      }
    },
    // 账户中心
    {
      name: 'accountcenter',
      path: '/accountcenter',
      component: (resolve) => {
        require(['../views/me/accountcenter.vue'], resolve);
      }
    },
    // 充值中心
    {
      name: 'accounts',
      path: '/accounts',
      component: (resolve) => {
        require(['../views/me/accounts.vue'], resolve);
      }
    },
    // 月子中心
    {
      name: 'confinementList',
      path: '/confinementList',
      component: (resolve) => {
        require(['../views/me/confinement/confinementList.vue'], resolve);
      }
    }, {
      name: 'addConfinement',
      path: '/addConfinement',
      component: (resolve) => {
        require(['../views/me/confinement/addConfinement.vue'], resolve);
      }
    },
    // 晋级
    {
      name: 'upgrade',
      path: '/upgrade',
      component: (resolve) => {
        require(['../views/me/upgrade.vue'], resolve);
      }
    },
    {
      name: 'toupleader',
      path: '/toupleader',
      component: (resolve) => {
        require(['../views/me/toupleader.vue'], resolve);
      }
    },
    {
      name: 'feedback',
      path: '/feedback',
      component: (resolve) => {
        require(['../views/feedback/feedback.vue'], resolve);
      }
    },
    {
      name: 'eshop',
      path: '/eshop',
      component: (resolve) => {
        require(['../views/feedback/eshop.vue'], resolve);
      }
    },
    {
      name: 'download',
      path: '/download',
      component: (resolve) => {
        require(['../views/feedback/download.vue'], resolve);
      }
    },
    // 合同
    {
      name: 'contract',
      path: '/contract',
      component: (resolve) => {
        require(['../views/me/contract.vue'], resolve);
      }
    },
    {
      name: 'levelout',
      path: '/levelout',
      component: (resolve) => {
        require(['../views/me/levelout.vue'], resolve);
      }
    },
    {
      name: 'newlevelout',
      path: '/newlevelout',
      component: (resolve) => {
        require(['../views/me/newlevelout.vue'], resolve);
      }
    },
    {
      name: 'shippingaddress',
      path: '/shippingaddress',
      component: (resolve) => {
        require(['../views/me/shippingaddress.vue'], resolve);
      }
    },
    //门头补贴
    {
      name: 'doorSubsidylist',
      path: '/doorSubsidy/list',
      component: (resolve) => {
        require(['../views/me/doorSubsidy/list.vue'], resolve);
      }
    },
    {
      name: 'doorSubsidyapply',
      path: '/doorSubsidy/apply',
      component: (resolve) => {
        require(['../views/me/doorSubsidy/apply.vue'], resolve);
      }
    },
    //电商授权
    {
      name: 'eCommerceAuthorize',
      path: '/eCommerceAuthorize/list',
      component: (resolve) => {
        require(['../views/me/eCommerceAuthorize/list.vue'], resolve);
      }
    },
    {
      name: 'eCommerceAuthorize',
      path: '/eCommerceAuthorize/apply',
      meta: {
        keepAlive: true
      },
      component: (resolve) => {
        require(['../views/me/eCommerceAuthorize/apply.vue'], resolve);
      }
    },
    {
      name: 'eCommerceAuthorize',
      path: '/eCommerceAuthorize/backbond',
      component: (resolve) => {
        require(['../views/me/eCommerceAuthorize/backbond.vue'], resolve);
      }
    },
    {
      name: 'eCommerceAuthorize',
      path: '/eCommerceAuthorize/book',
      component: (resolve) => {
        require(['../views/me/eCommerceAuthorize/book.vue'], resolve);
      }
    },
    {
      name: 'product',
      path: '/product',
      component: (resolve) => {
        require(['../views/product/product.vue'], resolve);
      }
    },
    {
      name: 'newproduct',
      path: '/newproduct',
      component: (resolve) => {
        require(['../views/newproduct/product.vue'], resolve);
      },
      meta: {
        keepAlive: true
      }
    },
    {
      name: 'productdetails',
      path: '/productdetails',
      component: (resolve) => {
        require(['../views/product/productdetails.vue'], resolve);
      },
      beforeEnter: (to, from, next) => {
        from.path == '/shopCart' ? next('/product') : next();
      }
    },
    {
      name: 'orders',
      path: '/orders',
      component: (resolve) => {
        require(['../views/orders/orders.vue'], resolve);
      },
      children: [
        {
          path: '/',
          redirect: '/orders/order',
        },
        {
          path: 'order',
          name: 'order',

          component: (resolve) => {
            require(['../views/orders/order.vue'], resolve);
          },
          // beforeEnter: (to, from, next) => {
          //   from.path == '/me' ? next() : next('/me');
          // },
          children: [
            {
              path: 'orderdetails/:oid',
              name: 'orderdetails',
              component: (resolve) => {
                require(['../views/orders/orderdetails.vue'], resolve);
              }
            },
            {
              path: 'querydelivery',
              name: 'querydelivery',
              component: (resolve) => {
                require(['../views/orders/querydelivery.vue'], resolve);
              }
            }
          ]
        },
        {
          path: 'orderdetails/:oid',
          name: 'orderdetails',
          component: (resolve) => {
            require(['../views/orders/orderdetails.vue'], resolve);
          }
        },
        {
          path: 'querydelivery',
          name: 'querydelivery',
          component: (resolve) => {
            require(['../views/orders/querydelivery.vue'], resolve);
          }
        },
        {
          path: 'collection',
          name: 'collection',
          component: (resolve) => {
            require(['../views/orders/collection.vue'], resolve);
          },
          // beforeEnter:(to,from,next)=>{
          //   from.path=='/me' ? next():next('/me');
          // },
          children: [
            {
              path: 'collectiondetails/:pid',
              name: 'collectiondetails',
              component: (resolve) => {
                require(['../views/orders/collectiondetails.vue'], resolve);
              }
            }
          ]
        },
        {
          path: 'paymentorder',
          name: 'paymentorder',
          component: (resolve) => {
            require(['../views/orders/paymentorder.vue'], resolve);
          },
          children: [
            {
              name: 'paymentdetails',
              path: 'paymentdetails/:pid',
              component: (resolve) => {
                require(['../views/orders/paymentDetails.vue'], resolve);
              }
            },
            {
              name: 'invoice',
              path: 'invoice',
              component: (resolve) => {
                require(['../views/orders/invoice.vue'], resolve);
              }
            }
          ]
        },
        {
          path: 'singleback',
          name: 'singleback',
          component: (resolve) => {
            require(['../views/orders/singleback.vue'], resolve);
          }
        }
      ]
    },
    {
      name: 'shopCart',
      path: '/shopCart',
      component: (resolve) => {
        require(['../views/orders/shopCart.vue'], resolve);
      }
    },
    {
      name: 'newshopCart',
      path: '/newshopCart',
      component: (resolve) => {
        require(['../views/orders/newshopCart.vue'], resolve);
      },
    },
    {
      name: 'myteam',
      path: '/myteam',
      component: (resolve) => {
        require(['../views/orders/myteam.vue'], resolve);
      }
    },
    {
      name: 'emptyPage',
      path: '/emptyPage',
      component: (resolve) => {
        require(['../views/product/emptyPage.vue'], resolve);
      },
    },


    // {
    //   name: "orderdetails",
    //   path: "/orderdetails",
    //   component: resolve => {
    //     require(['../views/orders/orderdetails.vue'], resolve)
    //   }
    // },
    // 支付
    {
      name: 'payment',
      path: '/payment',
      component: (resolve) => {
        require(['../views/me/payment.vue'], resolve);
      }
    },
    {
      path: '/archives',
      name: 'archives',
      component: (resolve) => {
        require(['../views/me/archives.vue'], resolve);
      }
    },
    {
      name: 'paymethodlist',
      path: '/paymethodlist',
      component: (resolve) => {
        require(['../views/me/paymethodlist.vue'], resolve);
      }
    },
    {
      path: '/productDetails2',
      name: 'productDetails2',
      component: (resolve) => {
        require(['../views/promotion/productDetails2.vue'], resolve);
      }
    },
    {
      path: '/mystocks',
      name: 'mystocks',
      component: (resolve) => {
        require(['../views/stock/mystocks.vue'], resolve);
      }
    },
    {
      path: '/stockin',
      name: 'stockin',
      component: (resolve) => {
        require(['../views/stock/stockin.vue'], resolve);
      }
    },
    {
      path: '/stockhistorys',
      name: 'stockhistorys',
      component: (resolve) => {
        require(['../views/stock/stockhistorys.vue'], resolve);
      }
    },
    {
      path: '/checkstocklist',
      name: 'checkstocklist',
      component: (resolve) => {
        require(['../views/stock/checkstocklist.vue'], resolve);
      }
    },
    {
      path: '/checkstockdifflist',
      name: 'checkstockdifflist',
      component: (resolve) => {
        require(['../views/stock/checkstockdifflist.vue'], resolve);
      }
    },
    {
      path: '/newYactivityC',
      name: 'newYactivityC',
      component: (resolve) => {
        require(['../views/activity/newYactivityC.vue'], resolve);
      },
      meta: { noauth: true }
    },
    {
      path: '/womenStep',
      name: 'womenStep',
      component: (resolve) => {
        require(['../views/activity/womenStep.vue'], resolve);
      },
      meta: { noauth: true }
    },
    {
      path: '/winningList',
      name: 'winningList',
      component: (resolve) => {
        require(['../views/activity/winningList.vue'], resolve);
      },
      meta: { noauth: true }
    },
    {
      path: '/womenDay',
      name: 'womenDay',
      component: (resolve) => {
        require(['../views/activity/womenDay.vue'], resolve);
      },
      meta: { noauth: true }
    }, {
      path: '/prize',
      name: 'prize',
      component: (resolve) => {
        require(['../views/activity/prize.vue'], resolve);
      },
      meta: { noauth: true }
    },
    {
      path: '/womenactive',
      name: 'womenactive',
      component: (resolve) => {
        require(['../views/activity/womenactive.vue'], resolve);
      },
      meta: { noauth: true }
    },
    {
      path: '/womenactivetest',
      name: 'womenactivetest',
      component: (resolve) => {
        require(['../views/activity/womenactivetest.vue'], resolve);
      },
      meta: { noauth: true }
    },
    {
      path: '/bLogin',
      name: 'bLogin',
      component: (resolve) => {
        require(['../views/activity/bLogin.vue'], resolve);
      },
      meta: { noauth: true }
    },
    {
      path: '/kongactivity',
      name: 'kongactivity',
      component: (resolve) => {
        require(['../views/activity/kongactivity.vue'], resolve);
      },
      meta: { noauth: true }
    },
    {
      path: '/newyearCfirst',
      name: 'newyearCfirst',
      component: (resolve) => {
        require(['../views/activity/newyearC/newyearCfirst.vue'], resolve);
      },
      meta: { noauth: true }
    },
    {
      path: '/newYactivity',
      name: 'newYactivity',
      component: (resolve) => {
        require(['../views/activity/newYactivity.vue'], resolve);
      }
    },
    {
      path: '/checklist',
      name: 'checklist',
      component: (resolve) => {
        require(['../views/check/CheckList.vue'], resolve);
      }
    },
    {
      path: '/connect',
      name: 'connect',
      component: (resolve) => {
        require(['../views/check/connect.vue'], resolve);
      }
    },
    {
      path: '/registrationform',
      name: 'registrationform',
      component: (resolve) => {
        require(['../views/check/RegistrationForm.vue'], resolve);
      }
    },
    {
      path: '/inquiryform',
      name: 'inquiryform',
      component: (resolve) => {
        require(['../views/check/InquiryForm.vue'], resolve);
      }
    },
    {
      path: '/integral',
      name: 'integral',
      component: (resolve) => {
        require(['../views/me/integral.vue'], resolve);
      }
    },
    {
      path: '/pk',
      name: 'pk',
      component: (resolve) => {
        require(['../views/me/pk.vue'], resolve);
      }
    },
    {
      path: '/guide',
      name: 'guide',
      component: (resolve) => {
        require(['../views/guide/index.vue'], resolve);
      }
    },
    {
      path: '/successpay',
      name: 'successpay',
      component: (resolve) => {
        require(['../views/promotion/successpay.vue'], resolve);
      }
    },
    {
      path: '/special',
      name: 'special',
      component: (resolve) => {
        require(['../views/me/special.vue'], resolve);
      }
    },
    {
      path: '/specialDetail',
      name: 'specialDetail',
      component: (resolve) => {
        require(['../views/me/specialDetail.vue'], resolve);
      }
    },
    {
      path: '/specialSearch',
      name: 'specialSearch',
      component: (resolve) => {
        require(['../views/me/specialSearch.vue'], resolve);
      }
    },
    {
      name: 'feedback',
      path: '/feedback',
      component: (resolve) => {
        require(['../views/feedback/feedback.vue'], resolve);
      }
    },
    {
      name: 'boutique',
      path: '/boutique',
      component: (resolve) => {
        require(['../views/feedback/boutique.vue'], resolve);
      }
    }, {
      name: 'informList',
      path: '/informList',
      component: (resolve) => {
        require(['../views/feedback/informList.vue'], resolve);
      }
    }, {
      name: 'authorizations',
      path: '/authorizations',
      component: (resolve) => {
        require(['../views/feedback/authorization.vue'], resolve);
      }
    }, {
      name: 'notApplyShop',
      path: '/notApplyShop',
      component: (resolve) => {
        require(['../views/feedback/notApplyShop.vue'], resolve);
      }
    }, {
      name: 'transferList',
      path: '/transferList',
      component: (resolve) => {
        require(['../views/feedback/transferList.vue'], resolve);
      }
    },
    {
      name: 'downloadpdf',
      path: '/downloadpdf',
      component: (resolve) => {
        require(['../views/feedback/downloadpdf.vue'], resolve);
      }
    },
    {
      name: 'certification',
      path: '/certification',
      component: (resolve) => {
        require(['../views/feedback/certification.vue'], resolve);
      }
    },
    {
      name: 'boutiqueDetails',
      path: '/boutiqueDetails',
      component: (resolve) => {
        require(['../views/feedback/boutiqueDetails.vue'], resolve);
      }
    }, {
      name: 'receipt',
      path: '/receipt',
      component: (resolve) => {
        require(['../views/feedback/receipt.vue'], resolve);
      }
    },
    {
      name: 'applyShop',
      path: '/applyShop',
      component: (resolve) => {
        require(['../views/feedback/applyShop.vue'], resolve);
      }
    },
    {
      name: 'policy',
      path: '/policy',
      component: (resolve) => {
        require(['../views/feedback/policy.vue'], resolve);
      }
    },
    {
      name: 'eshop',
      path: '/eshop',
      component: (resolve) => {
        require(['../views/feedback/eshop.vue'], resolve);
      }
    },
    {
      name: 'download',
      path: '/download',
      component: (resolve) => {
        require(['../views/feedback/download.vue'], resolve);
      }
    },
    {
      name: 'bazaarList',
      path: '/bazaarList',
      component: (resolve) => {
        require(['../views/orders/bazaarList.vue'], resolve);
      }
    }, {
      name: 'bazaardetails',
      path: '/bazaardetails',
      component: (resolve) => {
        require(['../views/orders/bazaardetails.vue'], resolve);
      }
    },
    {
      name: 'bindex',
      path: '/bindex',
      component: (resolve) => {
        require(['../views/babytree/index.vue'], resolve);
      }
    }, {
      name: 'affirmorder',
      path: '/affirmorder',
      component: (resolve) => {
        require(['../views/babytree/affirmorder.vue'], resolve);
      }
    }, {
      name: 'courseApply',
      path: '/courseApply',
      component: (resolve) => {
        require(['../views/babytree/courseApply.vue'], resolve);
      }
    }, {
      name: 'coursedetails',
      path: '/coursedetails',
      component: (resolve) => {
        require(['../views/babytree/coursedetails.vue'], resolve);
      }
    }, {
      name: 'baffirmorder',
      path: '/baffirmorder',
      component: (resolve) => {
        require(['../views/babytree/affirmorder.vue'], resolve);
      }
    }, {
      name: 'borderList',
      path: '/borderList',
      component: (resolve) => {
        require(['../views/babytree/orderList.vue'], resolve);
      }
    }, {
      name: 'borderdetails',
      path: '/borderdetails',
      component: (resolve) => {
        require(['../views/babytree/orderdetails.vue'], resolve);
      }
    }, {
      name: 'courseLearning',
      path: '/courseLearning',
      component: (resolve) => {
        require(['../views/babytree/courseLearning.vue'], resolve);
      }
    }, {
      name: 'examList',
      path: '/examList',
      component: (resolve) => {
        require(['../views/babytree/examList.vue'], resolve);
      }
    }, {
      name: 'examList',
      path: '/examList',
      component: (resolve) => {
        require(['../views/babytree/examList.vue'], resolve);
      }
    }, {
      name: 'introduce',
      path: '/introduce',
      component: (resolve) => {
        require(['../views/babytree/introduce.vue'], resolve);
      }
    }, {
      name: 'bwxpay',
      path: '/bwxpay',
      component: (resolve) => {
        require(['../views/babytree/wxpay.vue'], resolve);
      }
    }, {
      name: 'succeedPay',
      path: '/succeedPay',
      component: (resolve) => {
        require(['../views/babytree/succeedPay.vue'], resolve);
      }
    },
    {
      name: 'answerList',
      path: '/answerList',
      component: (resolve) => {
        require(['../views/babytree/answerList.vue'], resolve);
      }
    }, {
      name: 'answerRecord',
      path: '/answerRecord',
      component: (resolve) => {
        require(['../views/babytree/answerRecord.vue'], resolve);
      }
    }, {
      name: 'certificatedetails',
      path: '/certificatedetails',
      component: (resolve) => {
        require(['../views/babytree/certificatedetails.vue'], resolve);
      }
    }, {
      name: 'certificateList',
      path: '/certificateList',
      component: (resolve) => {
        require(['../views/babytree/certificateList.vue'], resolve);
      }
    }, {
      name: 'prizeManage',
      path: '/prizeManage',
      component: (resolve) => {
        require(['../views/prizeManage/index.vue'], resolve);
      }
    }, {
      name: 'prizeDetail',
      path: '/prizeDetail',
      component: (resolve) => {
        require(['../views/prizeManage/detail.vue'], resolve);
      }
    }, {
      name: 'limitingList',
      path: '/limitingList',
      component: (resolve) => {
        require(['../views/index/limitingList.vue'], resolve);
      }
    }, {
      name: 'couponGoods',
      path: '/couponGoods',
      component: (resolve) => {
        require(['../views/index/couponGoods.vue'], resolve);
      }
    }, {
      name: 'wisdomSnacks',
      path: '/wisdomSnacks',
      component: (resolve) => {
        require(['../views/me/wisdomSnacks.vue'], resolve);
      }
    }, {
      name: 'bperformance',
      path: '/bperformance',
      component: (resolve) => {
        require(['../views/me/bperformance.vue'], resolve);
      }
    }, {
      name: 'personage',
      path: '/personage',
      component: (resolve) => {
        require(['../views/me/personage.vue'], resolve);
      }
    }, {
      name: 'annuity',
      path: '/annuity',
      component: (resolve) => {
        require(['../views/me/annuity.vue'], resolve);
      }
    }, {
      name: 'integralReport',
      path: '/integralReport',
      component: (resolve) => {
        require(['../views/me/common/integralReport.vue'], resolve);
      }
    }, {
      name: 'dyAuthorize',
      path: '/dyAuthorize',
      component: (resolve) => {
        require(['../views/dyAuthorize/index.vue'], resolve);
      }
    }, {
      name: 'agreement',
      path: '/agreement',
      component: (resolve) => {
        require(['../views/dyAuthorize/agreement.vue'], resolve);
      }
    }, {
      name: 'apply',
      path: '/apply',
      component: (resolve) => {
        require(['../views/dyAuthorize/apply.vue'], resolve);
      }
    }, {
      name: 'dyAuthorizeDel',
      path: '/dyAuthorizeDel',
      component: (resolve) => {
        require(['../views/dyAuthorize/dyAuthorizeDel.vue'], resolve);
      }
    }, {
      name: 'certificate',
      path: '/certificate',
      component: (resolve) => {
        require(['../views/dyAuthorize/certificate.vue'], resolve);
      }
    }, {
      name: 'twist',
      path: '/twist/luckDraw',
      component: (resolve) => {
        require(['../views/activity/twist/luckDraw.vue'], resolve);
      }
    }, {
      name: 'goldenegg',
      path: '/twist/goldenegg',
      component: (resolve) => {
        require(['../views/activity/twist/goldenegg.vue'], resolve);
      }
    },
    {
      name: 'twistPrize',
      path: '/twist/prize',
      component: (resolve) => {
        require(['../views/activity/twist/prizeList.vue'], resolve);
      }
    }, {
      name: 'twistShare',
      path: '/twist/share',
      component: (resolve) => {
        require(['../views/activity/twist/share.vue'], resolve);
      }
    }, {
      name: 'twistShare',
      path: '/twist/shareList',
      component: (resolve) => {
        require(['../views/activity/twist/shareList.vue'], resolve);
      }
    }, {
      name: 'twistCart',
      path: '/twist/cart',
      component: (resolve) => {
        require(['../views/activity/twist/cart.vue'], resolve);
      }
    }, {
      name: 'twistSucceed',
      path: '/twist/succeed',
      component: (resolve) => {
        require(['../views/activity/twist/succeed.vue'], resolve);
      }
    }, {
      name: 'poster',
      path: '/twist/poster',
      component: (resolve) => {
        require(['../views/activity/twist/poster.vue'], resolve);
      }
    }, {
      name: 'advancedLuckDraw',
      path: '/twist/advancedLuckDraw',
      component: (resolve) => {
        require(['../views/activity/twist/advancedLuckDraw.vue'], resolve);
      }
    }, {
      name: 'replacement',
      path: '/replacement',
      component: (resolve) => {
        require(['../views/activity/replacement/index.vue'], resolve);
      }
    }, {
      name: 'dyindex',
      path: '/dy/index',
      meta: {
        keepAlive: true
      },
      component: (resolve) => {
        require(['../views/dyAuthorize/index/index.vue'], resolve);
      }
    }, {
      name: 'policy',
      path: '/dy/policy',
      meta: {
        keepAlive: true
      },
      component: (resolve) => {
        require(['../views/dyAuthorize/policy/index.vue'], resolve);
      }
    }, {
      name: 'exchangeRecord',
      path: '/dy/exchangeRecord',
      component: (resolve) => {
        require(['../views/dyAuthorize/policy/exchangeRecord.vue'], resolve);
      }
    }, {
      name: 'uid',
      path: '/dy/uid',
      component: (resolve) => {
        require(['../views/dyAuthorize/uid/record.vue'], resolve);
      }
    }, {
      name: 'adduid',
      path: '/dy/adduid',
      component: (resolve) => {
        require(['../views/dyAuthorize/uid/add.vue'], resolve);
      }
    }, {
      name: 'edituid',
      path: '/dy/edituid',
      component: (resolve) => {
        require(['../views/dyAuthorize/uid/edit.vue'], resolve);
      }
    }, {
      name: 'giveuid',
      path: '/dy/giveuid',
      component: (resolve) => {
        require(['../views/dyAuthorize/uid/give.vue'], resolve);
      }
    }, {
      name: 'train',
      path: '/dy/train',
      component: (resolve) => {
        require(['../views/dyAuthorize/train/index.vue'], resolve);
      }
    }, {
      name: 'signUp',
      path: '/dy/signUp',
      component: (resolve) => {
        require(['../views/dyAuthorize/signUp/index.vue'], resolve);
      }
    }, {
      name: 'signUpAdd',
      path: '/dy/signUpAdd',
      component: (resolve) => {
        require(['../views/dyAuthorize/signUp/add.vue'], resolve);
      }
    }, {
      name: 'productPallet',
      path: '/dy/productPallet',
      meta: {
        keepAlive: true
      },
      component: (resolve) => {
        require(['../views/dyAuthorize/products/pallet.vue'], resolve);
      }
    }, {
      name: 'productMaterial',
      path: '/dy/productMaterial',
      component: (resolve) => {
        require(['../views/dyAuthorize/products/material.vue'], resolve);
      }
    }, {
      name: 'productActivity',
      path: '/dy/productActivity',
      meta: {
        keepAlive: true
      },
      component: (resolve) => {
        require(['../views/dyAuthorize/products/activity.vue'], resolve);
      }
    }, {
      name: 'video',
      path: '/dy/video',
      meta: {
        keepAlive: true
      },
      component: (resolve) => {
        require(['../views/dyAuthorize/video/index.vue'], resolve);
      }
    }, {
      name: 'videoplay',
      path: '/dy/videoplay',
      component: (resolve) => {
        require(['../views/dyAuthorize/video/play.vue'], resolve);
      }
    }, {
      name: 'trainvideoplay',
      path: '/dy/trainvideoplay',
      component: (resolve) => {
        require(['../views/dyAuthorize/train/play.vue'], resolve);
      }
    }, {
      name: 'enroll',
      path: '/enroll',
      component: (resolve) => {
        require(['../views/me/enroll/index.vue'], resolve);
      }
    }, {
      name: 'enrollDetails',
      path: '/enrollDetails',
      component: (resolve) => {
        require(['../views/me/enroll/enrollDetails.vue'], resolve);
      }
    }, {
      name: 'enrollList',
      path: '/enrollList',
      component: (resolve) => {
        require(['../views/me/enroll/enrollList.vue'], resolve);
      }
    }, {
      name: 'shipperList',
      path: '/shipperList',
      component: (resolve) => {
        require(['../views/orders/shipperList.vue'], resolve);
      }
    }, {
      name: 'pickupgoods',
      path: '/pickupgoods',
      component: (resolve) => {
        require(['../views/pickUpGoods/index.vue'], resolve);
      }
    },
    {
      name: 'searchKeyList',
      path: '/searchkeylist',
      component: (resolve) => {
        require(['../views/common/searchKeyList.vue'], resolve);
      },
      meta: {
        keepAlive: true
      }
    },
    // 领劵中心
    {
      name: 'couponCenter',
      path: '/couponCenter',
      component: (resolve) => {
        require(['../views/me/couponCenter/couponCenter.vue'], resolve);
      },
      meta: {
        keepAlive: true
      }
    },
    {
      name: 'hxList',
      path: '/hxList',
      component: (resolve) => {
        require(['../views/me/couponCenter/hxList.vue'], resolve);
      }
    },
    // 领劵中心列表
    {
      name: 'couponCenterList',
      path: '/couponCenterList',
      component: (resolve) => {
        require(['../views/me/couponCenter/couponCenterList.vue'], resolve);
      },
      meta: {
        keepAlive: true
      }
    },
    // 我的卷包
    {
      name: 'couponMybag',
      path: '/couponMybag',
      component: (resolve) => {
        require(['../views/me/couponCenter/couponMybag.vue'], resolve);
      },
      meta: {
        keepAlive: true
      }
    },
    {//券活动列表
      name: 'coupon_index',
      path: '/coupon',
      component: (resolve) => {
        require(['../views/activity/coupon/index.vue'], resolve);
      },
      children: [
        {
          // 当 /coupon/coupon_list 匹配成功
          // 将被渲染 <router-view> 内部
          path: 'coupon_list',
          component: (resolve) => {
            require(['@/views/activity/coupon/couponList.vue'], resolve);
          },
        },
        {
          path: 'received_list',
          component: (resolve) => {
            require(['@/views/activity/coupon/receivedList.vue'], resolve);
          },
        },
      ],
    },
    {
      name: 'syz_list',
      path: '/syz_list',
      component: (resolve) => {
        require(['../views/me/syz_list.vue'], resolve);
      }
    },
    {
      name: 'syz_detial',
      path: '/syz_detial',
      component: (resolve) => {
        require(['../views/me/syz_detial.vue'], resolve);
      }
    },
    //试用装核销新
    {
      name: 'writeOffList',
      path: '/writeOff/list',
      component: (resolve) => {
        require(['../views/me/writeOff/list.vue'], resolve);
      }
    },
    {
      name: 'writeOffRecord',
      path: '/writeOff/record',
      component: (resolve) => {
        require(['../views/me/writeOff/record.vue'], resolve);
      }
    },
    {
      name: 'PKlist',
      path: '/writeOff/PKlist',
      component: (resolve) => {
        require(['../views/me/writeOff/PKlist.vue'], resolve);
      }
    },
    {
      name: 'PKlist',
      path: '/writeOff/PKdetial',
      component: (resolve) => {
        require(['../views/me/writeOff/PKdetial.vue'], resolve);
      }
    },
    //晒单工具
    {
      name: 'sunbathingTool',
      path: '/sunbathingTool',
      component: (resolve) => {
        require(['../views/me/sunbathingTool/activeList'], resolve);
      }
    },
    {
      name: 'sunbathingRank',
      path: '/sunbathingTool/rank',
      component: (resolve) => {
        require(['../views/me/sunbathingTool/rank'], resolve);
      }
    },
    {
      name: 'activedetial',
      path: '/sunbathingTool/activedetial',
      component: (resolve) => {
        require(['../views/me/sunbathingTool/activedetial'], resolve);
      }
    },
    {
      name: 'sunbathingList',
      path: '/sunbathingTool/sunbathingList',
      component: (resolve) => {
        require(['../views/me/sunbathingTool/sunbathingList'], resolve);
      }
    },
    {
      name: 'sunbathingList',
      path: '/sunbathingTool/sunbathingform',
      component: (resolve) => {
        require(['../views/me/sunbathingTool/sunbathingform'], resolve);
      }
    },
    //专题
    {
      name: 'specialtheme',
      path: '/specialtheme/theme',
      component: (resolve) => {
        require(['../views/specialtheme/theme'], resolve);
      },
      meta: {
        keepAlive: true
      }
    },
    {
      name: 'themeRecord',
      path: '/specialtheme/record',
      component: (resolve) => {
        require(['../views/specialtheme/record'], resolve);
      }
    },
    {
      name: 'themeOrder',
      path: '/specialtheme/themeOrder',
      component: (resolve) => {
        require(['../views/specialtheme/themeOrder'], resolve);
      }
    },
    //商品详情
    {
      name: 'goodDetials',
      path: '/newproduct/goodDetials',
      component: (resolve) => {
        require(['../views/newproduct/goodDetials'], resolve);
      }
    },
    //登录其他账号
    {
      name: 'loginOther',
      path: '/loginOther',
      component: (resolve) => {
        require(['../views/me/loginOther'], resolve);
      }
    },
    //拼团
    {
      name: 'collage',
      path: '/collage/list',
      component: (resolve) => {
        require(['../views/collage/list'], resolve);
      }
    },
    {
      name: 'collageDetial',
      path: '/collage/collageDetial',
      component: (resolve) => {
        require(['../views/collage/collageDetial'], resolve);
      }
    },
    {
      name: 'collageUserlist',
      path: '/collage/collageUserlist',
      component: (resolve) => {
        require(['../views/collage/collageUserlist'], resolve);
      }
    },
    {
      name: 'orderSubmit',
      path: '/collage/orderSubmit',
      component: (resolve) => {
        require(['../views/collage/orderSubmit'], resolve);
      }
    },
    {
      name: 'myorders',
      path: '/collage/myorders',
      component: (resolve) => {
        require(['../views/collage/myorders'], resolve);
      }
    },
    {
      name: 'groupDetial',
      path: '/collage/groupDetial',
      component: (resolve) => {
        require(['../views/collage/groupDetial'], resolve);
      }
    },
    //分享邀请
    {
      name: 'invitationPicture',
      path: '/invitationPicture',
      component: (resolve) => {
        require(['../views/me/invitationPicture'], resolve);
      }
    },
    //我的储值卡
    {
      name: 'stored',
      path: '/stored',
      component: (resolve) => {
        require(['../views/me/stored'], resolve);
      }
    },
    //储值卡明细
    {
      name: 'storedDetails',
      path: '/storedDetails',
      component: (resolve) => {
        require(['../views/me/storedDetails'], resolve);
      }
    },
    // 储值卡海报
    {
      name: 'storedQode',
      path: '/storedQode',
      component: (resolve) => {
        require(['../views/me/storedQode'], resolve);
      }
    },
    // 兑换券列表
    {
      name: 'exchangeList',
      path: '/exchangeList',
      component: (resolve) => {
        require(['../views/me/exchange/exchangeList'], resolve);
      }
    },
    // // 兑换券详情
    {
      name: 'exchangeDetails',
      path: '/exchangeDetails',
      component: (resolve) => {
        require(['../views/me/exchange/exchangeDetails'], resolve);
      }
    },
    // 申请退出
    {
      name: 'Signout',
      path: '/Signout',
      component: (resolve) => {
        require(['../views/me/Signout'], resolve);
      }
    },
     // 自定义哦配置页
     {
      name: 'zdy_page',
      path: '/zdy_page',
      component: (resolve) => {
        require(['../views/homeIndex/zdy_page'], resolve);
      }
    },
    //注册
    {
      name: 'register',
      path: '/register',
      component: (resolve) => {
        require(['../views/login/register/index.vue'], resolve);
      },
      meta: {
        keepAlive: true
      },
    },
    {
      name: 'register_apply',
      path: '/register_apply',
      component: (resolve) => {
        require(['../views/login/register/apply.vue'], resolve);
      }
    },
    {
      name: 'register_order',
      path: '/register_order',
      component: (resolve) => {
        require(['../views/login/register/order.vue'], resolve);
      }
    },
    {
      name: 'Mymessage',
      path: '/Mymessage',
      component: (resolve) => {
        require(['../views/me/Mymessage.vue'], resolve);
      }
    },
  ],
});

// 不重定向 白名单
const whiteList = [
  '/login',
  '/forgotpwd',
  '/newcontract',
  '/registered',
  '/wxlogin',
  '/successful',
  '/newYactivityC',
  '/static',
  '/system',
  '/shopCart2',
  '/actsign',
  '/kongactivity',
  '/guide',
  '/womenDay',
  '/successpay',
  '/wxpays',
  '/audit',
  '/links',
  '/dynamicList',
  '/rollList',
  '/addDynamic',
  '/wxlinks',
  '/qrCode',
  '/dynamicDetails',
  '/bwxpay',
  '/addPaymethod',
  '/dy/trainvideoplay',
  '/eCommerceAuthorize/apply',
  '/eCommerceAuthorize/book',
  '/loginOther',
  '/me',
  // '/sunbathingTool/activedetial'
  // '/promotion'
  // '/index'
];

router.beforeEach((to, from, next) => {
  if (sessionStorage.getItem('tokentwo')) {
    if (to.path === '/login' && !to.query.err) {
      next({ path: '/' });
    } else {
      next();
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1 || to.meta.noauth) {
      next();
    } else {
      if (to.query.res) {
        let q = to.query.res;
        let data = decodeURIComponent(Base64.decode(q));
        let key = JSON.parse(data);
        sessionStorage.setItem("temporary", key.temporary);
        sessionStorage.setItem("tokentwo", key.token);
        sessionStorage.setItem(
          "refresh",
          des.encryptByDES(key.refresh_token, "Ckil845@#$%^_=258Lo{,.}")
        );
        sessionStorage.setItem("avatar", key.avatar);
        to.query.res=""
        next({ path: to.path, query: to.query });
      } else if(to.query.token){
        sessionStorage.setItem("tokentwo", to.query.token);
        next({ path: to.path, query: to.query });
      }else{
        let fullPath = encodeURIComponent(to.fullPath)
        let from = ''
        if (to.query.kind){
          from=to.query.kind
        }else if(to.query.sharetype=="2"){
          from="抽奖分享"
        }else if(to.query.sharetype){
          from=to.query.sharetype
        }
        let shareid=''
        if (to.query.shareid){
          shareid=to.query.shareid
        }
        next(`/wxlogin?redirect=${fullPath}&from=${from}&shareid=${shareid}`);
      }
    }
  }
  // if (
  //   to.path === '/mystocks' ||
  //   to.path === '/stockin' ||
  //   to.path === '/stockhistorys' ||
  //   to.path === '/checkstocklist' ||
  //   to.path === '/checkstockdifflist'
  // ) {
  //   sensors.quick('autoTrackSinglePage');
  // }
});
export default router;
